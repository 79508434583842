// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-events-and-activities-event-details-tsx": () => import("./../../../src/components/EventsAndActivities/EventDetails.tsx" /* webpackChunkName: "component---src-components-events-and-activities-event-details-tsx" */),
  "component---src-components-projects-project-details-tsx": () => import("./../../../src/components/Projects/ProjectDetails.tsx" /* webpackChunkName: "component---src-components-projects-project-details-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acss-week-2021-tsx": () => import("./../../../src/pages/acss-week-2021.tsx" /* webpackChunkName: "component---src-pages-acss-week-2021-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-developers-tsx": () => import("./../../../src/pages/developers.tsx" /* webpackChunkName: "component---src-pages-developers-tsx" */),
  "component---src-pages-events-and-activities-tsx": () => import("./../../../src/pages/events-and-activities.tsx" /* webpackChunkName: "component---src-pages-events-and-activities-tsx" */),
  "component---src-pages-finals-cube-tsx": () => import("./../../../src/pages/finals/cube.tsx" /* webpackChunkName: "component---src-pages-finals-cube-tsx" */),
  "component---src-pages-finals-execteamisthebestteam-tsx": () => import("./../../../src/pages/finals/execteamisthebestteam.tsx" /* webpackChunkName: "component---src-pages-finals-execteamisthebestteam-tsx" */),
  "component---src-pages-finals-welcomeybtwelvepromax-tsx": () => import("./../../../src/pages/finals/welcomeybtwelvepromax.tsx" /* webpackChunkName: "component---src-pages-finals-welcomeybtwelvepromax-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

